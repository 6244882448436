import Rails from '@rails/ujs';

if (!window.Rails) {
  Rails.start();
}

import { Controller } from "@hotwired/stimulus"
const homeclass = document.querySelector('.home');

export default class extends Controller {
  static targets = ['loadMoreAboveLink', 'loadMoreBelowLink']

  connect() {
    console.log('scrolling')
    //move the page up 1 pixel to trigger the scroll event
    window.scrollTo(0, 2)
  }

  scroll() {
    let height_diff = 700

    if (homeclass) { height_diff = 900 }

    var body = document.body
    var html = document.documentElement

    var height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight)

    if (window.pageYOffset >= (height - window.innerHeight - height_diff)) {
      if (this.loadMoreBelowLinkTarget.disabled) {
        return
      }
      this.clickAndDisable(this.loadMoreBelowLinkTarget)
    }
    
    if (window.pageYOffset <= height_diff) {
      if (this.loadMoreAboveLinkTarget.disabled) {
        return
      }
      this.clickAndDisable(this.loadMoreAboveLinkTarget)
    }
  }

  clickAndDisable(target) {
    target.click()
    target.disabled = true
    target.removeEventListener('click', target)
  }
}
